import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ButtonLoader from '../loaders/ButtonLoader';
import { useGlobalContext } from '../../context/context';
import { FaTimes } from 'react-icons/fa';

const UpdateAccount = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { baseURL, setUserToken, userToken, userDetails, setUpdateInfo } =
    useGlobalContext();

  // GET USER TOKEN FROM LOCAL STORAGE
  useEffect(() => {
    setUserToken(JSON.parse(window.localStorage.getItem(`userToken`)));

    // eslint-disable-next-line
  }, [userToken]);

  const formik = useFormik({
    initialValues: {
      name: `${userDetails.name}`,
      zipCode: `${userDetails.zipCode}`,
      address: `${userDetails.address}`,
      gender: `${userDetails.gender}`,
      phoneNumber: `${userDetails.phoneNumber}`,
      investmentGoal: `${userDetails.investmentGoal}`,
    },
    validationSchema: Yup.object({
      name: Yup.string().matches(
        /^[a-zA-Z]+ [a-zA-Z]+$/,
        'Please enter a valid full name'
      ),

      phoneNumber: Yup.string().matches(
        /^\d{10}$/,
        'Please enter a valid 10-digit phone number '
      ),
      zipCode: Yup.string()
        .max(5, `Maximum of 5 characters`)
        .matches(/^\d{5}$/, 'Please enter a valid Zip code'),

      address: Yup.string().min(
        5,
        'Address must be at least 5 characters long'
      ),
    }),
    onSubmit() {
      updateInfo();
    },
  });

  // FUNCTION TO UPDATE USER
  const updateInfo = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${baseURL}/auth/edit-user`, {
        method: 'PUT',
        body: JSON.stringify(formik.values),
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      });
      // eslint-disable-next-line
      const data = await response.json();
      setIsLoading(false);
      setUpdateInfo(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <article className='update-info'>
      <div className='modal'>
        <div className='title-close_btn'>
          <h2>Update Account Information</h2>
          <FaTimes className='close-btn' onClick={() => setUpdateInfo(false)} />
        </div>
        <form onSubmit={formik.handleSubmit} className='update-info-form'>
          {/* FULL NAME */}
          <div className='form-control'>
            <label
              htmlFor='full-name'
              className={
                formik.touched.name && formik.errors.name ? `red` : null
              }
            >
              {formik.touched.name && formik.errors.name
                ? formik.errors.name
                : `Full Name`}
            </label>
            <input
              type='text'
              placeholder='John Doe'
              id='full-name'
              className='input'
              name='name'
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* PHONE NUMBER */}
          <div className='form-control'>
            <label
              htmlFor='phone-number'
              className={
                formik.touched.phoneNumber && formik.errors.phoneNumber
                  ? `red`
                  : null
              }
            >
              {formik.touched.phoneNumber && formik.errors.phoneNumber
                ? formik.errors.phoneNumber
                : `Phone Number`}
            </label>
            <input
              type='tel'
              placeholder='xxx-xxx-xxx-x'
              id='phone-number'
              className={formik.errors.phoneNumber ? `input red` : 'input'}
              name='phoneNumber'
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* ADDRESS */}
          <div className='form-control'>
            <label
              htmlFor='address'
              className={
                formik.touched.address && formik.errors.address ? `red` : null
              }
            >
              {formik.touched.address && formik.errors.address
                ? formik.errors.address
                : `Address`}
            </label>
            <input
              type='text'
              placeholder='No 2, Joy street, NJ, USA'
              id='address'
              className='input'
              name='address'
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* GENDER */}
          <div className='form-control'>
            <label
              htmlFor='gender'
              className={
                formik.touched.gender && formik.errors.gender ? `red` : null
              }
            >
              {formik.touched.gender && formik.errors.gender
                ? formik.errors.gender
                : `Gender`}
            </label>
            <input
              type='text'
              id='gender'
              className='input'
              name='gender'
              value={formik.values.gender}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* INVESTMENT GOAL */}
          <div className='form-control'>
            <label
              htmlFor='investment-goal'
              className={
                formik.touched.investmentGoal && formik.errors.investmentGoal
                  ? `red`
                  : null
              }
            >
              {formik.touched.investmentGoal && formik.errors.investmentGoal
                ? formik.errors.investmentGoal
                : `Investment Goal`}
            </label>
            <select
              name='investmentGoal'
              id='investment-goal'
              value={formik.values.investmentGoal}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option hidden>Select Investment Goal</option>
              <option value='none'>None</option>
              <option value='Investing for Retirement'>
                Investing for Retirement
              </option>
              <option value='Investing to buy house'>
                Investing to buy house
              </option>
              <option value='A Career Change'>A Career Change</option>
              <option value='Starting a business'>Starting a business</option>
            </select>
          </div>

          {/* ZIP CODE */}
          <div className='form-control'>
            <label
              htmlFor='zipCode'
              className={
                formik.touched.zipCode && formik.errors.zipCode ? `red` : null
              }
            >
              {formik.touched.zipCode && formik.errors.zipCode
                ? formik.errors.zipCode
                : `Zip Code`}
            </label>
            <input
              type='text'
              placeholder='4354543'
              id='zipCode'
              className='input'
              name='zipCode'
              value={formik.values.zipCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* SUBMIT BUTTON DIV */}
          <div className='submit-btn'>
            <button className='gold' onClick={() => setIsLoading(true)}>
              Update
            </button>
            {isLoading && <ButtonLoader />}
          </div>
        </form>
      </div>
    </article>
  );
};

export default UpdateAccount;
